// @ts-check
import { useEffect } from "react";

import { useState } from "react";
import {
  enumDocumentsSource,
  enumLookupTypes,
  enumPage,
  enumRoutes,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import { Grid, Typography } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import NotesIcon from "@mui/icons-material/Notes";
import EmployeeActions from "../../actions/EmployeeActions";
import moment from "moment";
import { MessageHelper } from "../../helpers/MessageHelper";
import DownloadActions from "../../actions/DownloadActions";
import SystemLogDetail from "../SystemLog/SystemLogDetail";
import MyDialogYesNo from "../../controls/MyDialogYesNo";
import FinProjectActions from "../../actions/FinProjectActions";
import MyDropDown from "../../controls/MyDropDown";
import CurrencyHelper from "../../helpers/CurrencyHelper";

export default function FinProjectHeader(args) {
  //Page MAIN Hooks
  const [Project, setProject] = useState({});
  const [ProjectVersions, setProjectVersions] = useState([]);
  const [MessageData, setMessageData] = useState({});

  //Da proveri dali e nov zapis ili postoecki
  const { ProjectID } = useParams();

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    if (ProjectID) {
      if (parseInt(ProjectID) > 0) {
        initData();
      }
    }
  }, [location.pathname + "/" + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */


  const initData = () => {
    async function fetchData() {

      //sega loadiraj Contracts by employee
      let payload = { fpProjectID: ProjectID };
      let responses = await new FinProjectActions().EditAsync(payload);
      if (!responses.error) {
        //Load Finished
        let projectData = responses[0].data;
        setProject(projectData);
        
        //now if is space for versioning load this as well
        if (parseInt(projectData.fpVersionNo) > 0) { 
          responses = await new FinProjectActions().GetVersionsAsync(payload);
          if (!responses.error) {
            //Load Finished
            let projectVersions = responses[0].data;
            setProjectVersions(projectVersions);
          }
          else {
            //Load with Error
            let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
            setMessageData(result);
            return false;
          }
        }
        return true;
      }
      else {
        //Load with Error
        let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
        return false;
      }

    }
    fetchData();
  };



  //****************************************** */
  //GUI DISPLAY
  //****************************************** */

  return (
    <Grid container sx={{ mb: 2 }}>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography variant="h3" display="block">
          {Project.fpName}
        </Typography>
        <Typography variant="h4" display="block">
          Budget Holder: {Project.fpOwnerName},
          Verifiers #1: {Project.fpHolderName}, #2: {Project.fpHolderName2}, #3: {Project.fpHolderName3},
          Phase: {Project.PhaseInfo}, Version: {Project.fpVersionNo}
        </Typography> 
      {/* <Typography variant="h6" display="block">
          Budget Amount:{new CurrencyHelper().ToMoneyFormatDigits(Project.fpAmountPlannedBudgetBalance, 0)} EUR,
          Allocated Amount:{new CurrencyHelper().ToMoneyFormatDigits(Project.fpAmountPlannedSpentBalance, 0)} EUR,
          Balance Amount: {new CurrencyHelper().ToMoneyFormatDigits(Project.fpAmountPlannedBalance, 0)} EUR
        </Typography>           */}
      </Grid> 
    </Grid> 
  );
}
