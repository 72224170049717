// @ts-check 
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MyText from '../../controls/MyText';
import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumBreadcrumbPage, enumContractType, enumLookupTypes, enumPage, enumPagination, enumRoutes, enumSharedMsgs } from '../../helpers/common.constants';
import ContractActions from '../../actions/ContractActions';
import Button from '@mui/material/Button';
import MyCheckList from '../../controls/MyCheckList';
import { Box, Breadcrumbs, Card, CardActions, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, useMediaQuery } from '@mui/material';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import LogHelper from '../../helpers/LogHelper';
import MyDate from '../../controls/MyDate';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import EmployeeBreadcrumbs from './EmployeeBreadcrumbs';
import NotesIcon from '@mui/icons-material/Notes';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import { MessageHelper } from '../../helpers/MessageHelper';
import MyNumber from '../../controls/MyNumber';
import EditIcon from "@mui/icons-material/Edit";
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import MyDropDown from '../../controls/MyDropDown';
import EmployeeActions from '../../actions/EmployeeActions';
import DownloadActions from '../../actions/DownloadActions';
import EmployeeHeaderButtons from './EmployeeHeaderButtons';
import MyDialog from '../../controls/MyDialog';
import SystemLogDetail from '../SystemLog/SystemLogDetail';
import { ModeComment } from '@mui/icons-material';
import dayjs from 'dayjs';
import MyDate2 from '../../controls/MyDate2';

const RecordDefault =
{
  "ParentName": "string",
  "ParentItemGUID": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "ParentID": 0,
  "ctContractID": 0,
  "ctEmployeeID": "0",
  "ctContractedHours": 0,
  "ctStartDateContract": "2023-06-08T06:20:41.145Z",
  "ctEndDateContract": "2023-06-08T06:20:41.145Z",
  "ctHoursAwarded": 0,
  "ctDetailsHoursPassed": "string",
  "ctTotalDirectDays": 0,
  "ctFTE": 0,
  "ctContractCurrencyID": 0,
  "ctGrossNetID": 0,
  "ctActualSallary": 0,
  "ctFullTimeSallary": 0,
  "ctTypeOfContractID": 0,
  "ctItemGUID": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "ctCreatedBy": "string",
  "ctCreatedDate": "2023-06-08T06:20:41.146Z"
};


export default function ContractManage() {

  const [LookupData, setLookupData] = useState([]);
  const [Employee, setEmployee] = useState(RecordDefault);
  const [Record, setRecord] = useState(RecordDefault);
  const [InitialRecord, setInitialRecord] = useState(RecordDefault);

  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const { EmployeeID } = useParams();
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsLocked, setIsLocked] = useState(false);
  const [IsAllowedHoursEnabled, setIsAllowedHoursEnabled] = useState(false);
  const [ForceSave, setForceSave] = useState(false);
  const [IsCalculate, setCalculate] = useState(false);
  const [ModalParams, setModalParams] = useState({ ShowModal: false, Content: "", OnModalClose: null, OnModalHasChanges: null, Arguments: null }); //ModalParams e objekt koj sto IsOpen i IsContent ima struktura


  //za Navigacija 
  const navigate = useNavigate();

  //za poraki pri loadiranje na strana 
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Iniicijalno povikaj initData i ako zavrsi se kako sto treba ke go setira IsInitDone: true koj sto ke go zapali hook-ot IsInitDone
  React.useEffect(() => { initData(); }, []); //default

  React.useEffect(() => {
    if (ForceSave) {
      saveData();
    }
  }, [ForceSave]);


  React.useEffect(() => {

    if (IsCalculate) {
      //alert("CALCULATE");
      calculateData();
    }
  }, [IsCalculate]);



  const initData = () => {
    async function fetchData() {

      //Clean all states  
      setIsInitDone(false);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let payload = { eyEmployeeID: EmployeeID }
      let responses = await new ContractActions().InitAsync(payload); // await LookupActions.InitAsync();
      if (!responses.error) {

        let lookupData = responses[0].data;
        let employeeData = responses[1].data;
        let contractNewData = responses[2].data;
        setLookupData(lookupData);
        setEmployee(employeeData);
        setRecord(contractNewData);
        setInitialRecord(contractNewData); //mu treba od koga ke klikne SAVE da go vrati na inicijalen setinzi

        
        //ako se desi greska ke si se napise samata
        await GetByEmployeeIDAsync();

        if (employeeData.eyRemoved) {
          result = msgHelper.setPhase(
            enumPage.LoadSuccessWithWarningMsg,
            null,
            "Profile is set as not active!"
          );
          setMessageData(result);
          setIsLocked(true);
        }

      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  async function GetByEmployeeIDAsync() {

    //sega loadiraj Contracts by employee
    let payload = { EmployeeID: EmployeeID };
    let responses = await new ContractActions().GetByEmployeeIDAsync(payload);
    if (!responses.error) {
      //Load Finished
      let recordsData = responses[0].data;
      setRecords(recordsData);

      let result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
      return true;
    }
    else {
      //Load with Error
      let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
      return false;
    }
  }

  const calculateData = () => {

    async function fetchData() {


      //napravi kalkulacii na server
      let responses = await new ContractActions().CalculateAsync(Record);
      if (!responses.error) {
        //Load Finished
        let contactData = responses[0].data;

        setRecord((a) => ({ ...a, ctHoursAwarded: contactData.ctHoursAwarded }));  //Samo ovdeka mu treba ako ima Validacioni greski da dodade

        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
        setCalculate(false);
        return true;
      }
      else {
        //Load with Error
        let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
        return false;
      }
    }

    fetchData();
  }

  const saveData = () => {
    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let response = await new ContractActions().SaveAsync(Record);

      if (!response.error) {

        //Refreshiraj cela Strana i pocekaj da zavrsi i posle ispisi SAVE poraka  
        const isOk = await GetByEmployeeIDAsync();

        if (isOk) {
          //Load Finished
          result = msgHelper.setPhase(enumPage.SaveSuccess);
          setMessageData(result);
          setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages da gi izbrise

          setRecord(InitialRecord);
          setForceSave(false);
        }
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages 
      }
    }

    fetchData();
  };

  const editData = (ContractId) => {
    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let payload = { ctContractID: ContractId };
      let responses = await new ContractActions().EditAsync(payload);
      if (!responses.error) {
        let contactData = responses[0].data;
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        setRecord(contactData);

        setIsAllowedHoursEnabled(false);
        // if (contactData.ctTypeOfContractID  === enumContractType.ContractType_TransferPreviousYear)
        // {
        //     setIsAllowedHoursEnabled(true); 
        // }

      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };

  const openSystemLogDialog = (ItemGUID) => {
    let args = { ReferenceNumber: Employee.eyItemGUID, SecondaryReference: ItemGUID }
    setModalParams({ ShowModal: true, OnModalClose: closeSystemLogDialog, Arguments: args });
  };


  const closeSystemLogDialog = (args) => {
    setModalParams({ ShowModal: false });
  }

  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));

    //proveri dali kalkculaciite se vikaat od stisnato KOPCE: SAVE
    if (e.relatedTarget) {

      if (e.relatedTarget.name === "btnSave") {
        setForceSave(true);
      }
    }
  };

  const handleChangeCalc = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);


    setRecord((prevState) => ({ ...prevState, [name]: value }));
    //Record[name] = value; //mora za da go osvezi state-ot vo Record
    //calculateData();

    //da trigerira kalkulacii
    if (Record[name] != value) {

      if (Record.ctTypeOfContractID !== enumContractType.ContractType_TransferPreviousYear)
      {
        setCalculate(true);
      }
    }

    //proveri dali kalkculaciite se vikaat od stisnato KOPCE: SAVE
    if (e.relatedTarget) {

      if (e.relatedTarget.name === "btnSave") {
        setForceSave(true);
      }
    }

  };



  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ActionButtons = () => {
    return (
      <Grid container align="right" padding={1}>
        <Grid item xs={12}>
          <Button variant="contained" name="btnSave" color="success" size="small" disabled={MessageData.Loading || IsLocked} onClick={() => saveData()}>Save</Button>
        </Grid>
      </Grid>
    )
  };


  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3" display="block">{Employee.eyFullName}</Typography>
        </Grid>
      </Grid>

      <EmployeeBreadcrumbs page={enumBreadcrumbPage.Notes} Id={Record.ctEmployeeID} disabled={MessageData.Loading} />
      <MyAlert {...MessageData} />

      <EmployeeHeaderButtons />
      <ActionButtons />


      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <MyDate2 Id='ctStartDateContract' PlaceHolder='Start Date' Record={Record} HandleChange={handleChangeCalc} />
        </Grid>
        <Grid item xs={12} md={3}>
          <MyDate2 Id='ctEndDateContract' PlaceHolder='End Date' Record={Record} HandleChange={handleChangeCalc} />
        </Grid>

        <Grid item xs={12} md={2}>
          <MyNumber Id='ctContractedHours' PlaceHolder='Hours per Week' Record={Record} HandleChange={handleChangeCalc} />
        </Grid>

        <Grid item xs={12} md={4}>
          <MyNumber Id='ctHoursAwarded' PlaceHolder='Holiday Allowance' Record={Record} HandleChange={handleChange} 
          Disabled={!IsAllowedHoursEnabled} />
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingBottom={5} sx={{ borderBottom: 1, borderColor: 'grey.500', pb: 1 }}>
        <Grid item xs={12} md={3}>
          <MyDropDown Id='ctTypeOfContractID'
          
            Record={Record}
            PlaceHolder='Type of Contract'
            HandleChange={handleChangeCalc}
            ValidationMessages={Record.ValidationMessages}
            Disabled={IsAllowedHoursEnabled}
            Options={LookupData}
            LookupTypeId={enumLookupTypes.Contract_Type}
            Required={true} 
            /> 
        </Grid>

        <Grid item xs={12} md={3}>
          <MyText Id='ctFTE' PlaceHolder='FTE' Record={Record} HandleChange={handleChange} />
        </Grid>

        <Grid item xs={12} md={2}>
          <MyDropDown Id='ctContractCurrencyID'
            Record={Record}
            PlaceHolder='Contract Currency'
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Options={LookupData}
            LookupTypeId={enumLookupTypes.Contract_Currency}
            Required={true} />
        </Grid>

        <Grid item xs={12} md={2}>
          <MyDropDown Id='ctGrossNetID'
            Record={Record}
            PlaceHolder='Gross/Net'
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Options={LookupData}
            LookupTypeId={enumLookupTypes.Contract_GrossNet}
            Required={true} />
        </Grid>

        <Grid item xs={12} md={2}>
          <MyNumber Id='ctFullTimeSallary' PlaceHolder='Contract Sallary' Record={Record} HandleChange={handleChange} />
        </Grid>

        <Grid item xs={12} md={2}>
          <MyNumber Id='ctActualSallary' PlaceHolder='Payroll Sallary (EUR)' Record={Record} HandleChange={handleChange} />
        </Grid> 
      </Grid>

      <TableContainer sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Contracted Hours</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Hours Awarded</TableCell>
              <TableCell>Gross/Net</TableCell>
              <TableCell>Contract Salary</TableCell>
              <TableCell>Payroll Salary</TableCell>
              <TableCell>Type of Contract</TableCell>
              <TableCell>FTE</TableCell>
              <TableCell></TableCell> 
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow key={row.anNoteID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {index + 1}.
                </TableCell>
                <TableCell>{row.ctContractedHours}</TableCell>
                <TableCell>{moment(row.ctStartDateContract).format("DD/MM/YYYY")}</TableCell>
                <TableCell>{moment(row.ctEndDateContract).format("DD/MM/YYYY")}</TableCell>
                <TableCell>{row.ctHoursAwarded}</TableCell>
                <TableCell>{row.ctGrossNetName}</TableCell>
                <TableCell>{row.ctFullTimeSallary} {row.ctContractCurrencyName}</TableCell>
                <TableCell>{row.ctActualSallary} EUR</TableCell>
                <TableCell>{row.ctTypeOfContractName}</TableCell>
                <TableCell>{row.ctFTE}</TableCell>
                <TableCell>
                  {row.AllowEdit && <Tooltip title="Edit">
                    <IconButton
                      size="small"
                      name="details"
                      onClick={() => editData(row.ctContractID)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  }

                  {<Tooltip title="System Log">
                    <IconButton
                      size="small"
                      name="details"
                      onClick={() => openSystemLogDialog(row.ctItemGUID)}
                    >
                      <DeviceUnknownIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  }
                </TableCell> 
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <Typography variant="body2" align='left' sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
        {Records.map((row, index) => (
          <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
            <CardContent>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h5">Contracted Hours</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ pl: 2.5 }}>
                    {row.ctContractedHours}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h5">Start Date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ pl: 2.5 }}>
                    {moment(row.ctStartDateContract).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h5">End Date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ pl: 2.5 }}>
                    {moment(row.ctEndDateContract).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Tooltip title="Edit">
                <IconButton
                  size="small"
                  name="details"
                  onClick={() => editData(row.ctContractID)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </CardActions>
          </Card>
        ))
        }
      </Typography>

      <SystemLogDetail IsOpen={ModalParams.ShowModal} HandleModalClose={ModalParams.OnModalClose} Props={ModalParams.Arguments} />

    </Typography>
  );
}
