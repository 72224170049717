// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";

import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import { enumPage, enumRoutes } from "../../helpers/common.constants";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import moment from "moment";
import FinProjectActions from "../../actions/FinProjectActions";
import { MessageHelper } from "../../helpers/MessageHelper";
import FinHeaderButtons from "./FinHeaderButtons";
import FinProjectHeader from "./FinProjectHeader";
import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DownloadActions from "../../actions/DownloadActions";
import Link from "@mui/material/Link";

const RecordDefault = {
  fpProjectID: 0,
  fpYear: moment(Date.now()).format("YYYY"),
  fpCode: "",
  fpName: "",
  fpDescription: "",
  fpStartDate: moment().startOf("year").format("YYYY-MM-DD"),
  fpEndDate: moment().endOf("year").format("YYYY-MM-DD"),
  fpVersionNo: 1,
  fpVersionDate: "1900-01-01",
  fpHolderCount: 0,
  fpOwnerID: -1,
  fpOwnerName: "",
  fpOwnerEmail: "",

  fpHolderID: -1,
  fpHolderName: "",
  fpHolderEmail: "",
  fpHolderID2: -1,
  fpHolderName2: "",
  fpHolderEmail2: "",
  fpHolderID3: -1,
  fpHolderName3: "",
  fpHolderEmail3: "",
  fpActiveFL: false,
  fpAmount: "",
  fpVerifiedFL: false,
  IsLocked: false,
};

export default function FinProjectOverviewPlanning(args) {
  // console.error("AcquisitionManage - ARGS");
  // console.error(args);

  //Page MAIN Hooks
  const [IsNewRecord, setIsNewRecord] = useState(true);
  const [IsInitDone, setIsInitDone] = useState(true);
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);

  const [LedgerByProject, setLedgerByProject] = useState([]);
  const [LedgerByBudget, setLedgerByBudget] = useState([]);
  const [LedgerByAllocation, setLedgerByAllocation] = useState([]);

  //Da proveri dali e nov zapis ili postoecki
  const { ProjectID, showMsg } = useParams();

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      setRecord(RecordDefault);
      setIsNewRecord(true);

      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      if (ProjectID) {
        let payload = { ProjectIDs: ProjectID, TypeView: 3 }; //3 -> Show only Project
        let response1 = await new FinProjectActions().GetLedgerByProjectAsync(
          payload
        ); // await LookupActions.InitAsync();

        let payload2 = { ProjectIDs: ProjectID, TypeView: 2 }; //3 -> Show only Budget
        let response2 = await new FinProjectActions().GetLedgerByBudgetAsync(
          payload2
        ); // await LookupActions.InitAsync();

        let payload3 = { ProjectIDs: ProjectID, TypeView: 1 }; //1 -> Show Allocation
        let response3 =
          await new FinProjectActions().GetLedgerByAllocationAsync(payload3); // await LookupActions.InitAsync();

        if (!response1.error) {
          let recordDb = response1[0].data;
          setLedgerByProject(recordDb);

          let recordDb2 = response2[0].data;
          setLedgerByBudget(recordDb2);

          let recordDb3 = response3[0].data;
          setLedgerByAllocation(recordDb3);

          setRecord(recordDb);
          setIsNewRecord(false);

          //Load Finished
          result = msgHelper.setPhase(enumPage.LoadSuccess);
          setMessageData(result);
        } else {
          //Finished  with Error
          result = msgHelper.setPhase(enumPage.ErrorServer, response1);
          setMessageData(result);
        }
      } else {
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const handleExcelExportLedgerProject = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStartWithAwait);
      setMessageData(result);

      let payload = { ProjectIDs: ProjectID, TypeView: 3 }; //3 -> Show only Project
      console.log("OD PROEKTTTT");
      console.log(payload);
      let responses = await new FinProjectActions().ExportAsyncLedgerProject(
        payload
      );

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE
        const excelFileUrl = responses[0].data;
        console.log(responses[0].data);
        let payload = "?inputFile=" + excelFileUrl;
        console.log(payload);
        let downloadResponse = await new DownloadActions().DownloadFileAsync(
          payload
        );

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const handleExcelExportLedgerBudget = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStartWithAwait);
      setMessageData(result);

      let payload2 = { ProjectIDs: ProjectID, TypeView: 2 };

      let responses = await new FinProjectActions().ExportAsyncLedgerBudget(
        payload2
      );

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE
        const excelFileUrl = responses[0].data;
        console.log(responses[0].data);
        let payload = "?inputFile=" + excelFileUrl;
        console.log(payload);
        let downloadResponse = await new DownloadActions().DownloadFileAsync(
          payload
        );

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const handleExcelExportLedgerAllocation = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStartWithAwait);
      setMessageData(result);

      let payload3 = { ProjectIDs: ProjectID, TypeView: 1 };
      let responses = await new FinProjectActions().ExportAsyncLedgerAllocation(
        payload3
      );

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE
        const excelFileUrl = responses[0].data;
        console.log(responses[0].data);
        let payload = "?inputFile=" + excelFileUrl;
        console.log(payload);
        let downloadResponse = await new DownloadActions().DownloadFileAsync(
          payload
        );

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      {ProjectID > 0 && <FinProjectHeader />}

      <FinHeaderButtons />

      <MyAlert {...MessageData} />

      <RenderProjectLedger
        Records={LedgerByProject}
        MessageData={MessageData}
        onExportExcelLedgerProject={handleExcelExportLedgerProject}
        onExportExcelLedgerBudget={handleExcelExportLedgerBudget}
        onExportExcelLedgerAllocation={handleExcelExportLedgerAllocation}
      />
      <br />
      <RenderProjectDaysLedger Records={LedgerByProject} MessageData={MessageData}/>
      <br/>
      <RenderBudgetLedger Records={LedgerByBudget} />
      <br />
      <RenderAllocationLedger Records={LedgerByAllocation} />
      <br/>
      <RenderReportedLedger Records={LedgerByAllocation} /> 
    </Typography>
  );
}

const RenderProjectLedger = ({
  Records,
  MessageData,
  onExportExcelLedgerProject,
  onExportExcelLedgerBudget,
  onExportExcelLedgerAllocation,
}) => {
  return React.useMemo(() => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={12}
                align="center"
                className="tablecell header"
              >
                <Typography variant="h5" align="center">
                  Project Planning
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                colSpan={9}
                align="center"
                className="tablecell header"
              >
                <Typography variant="h6" align="center">
                  Planned
                </Typography>
              </TableCell> 
              <TableCell
                colSpan={9}
                align="center"
                className="tablecell header" 
              >
                <Typography variant="h6" align="center">
                  Reported
                </Typography>
              </TableCell> 
              
            </TableRow>

            <TableRow>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                Spark Planned
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                Spark Allocated
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                Spark Balance
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Mgmt Planned
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                Mgmt  Allocated
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                Mgmt Balance
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Budget (Spark+Mgmt)
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Allocated (Spark+Mgmt)
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance (Spark+Mgmt)
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Budget Planned
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  TS Reported 
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance
                </Typography>
              </TableCell>              
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow
                key={row.AlocationID}
                className="table-row"> 
                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountSparkPlanned,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountSparkAllocated,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountSparkBalance,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountMgmtPlanned,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountMgmtAllocated,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountMgmtBalance,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountBalancePlanned,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountBalanceAllocated,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountBalance,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountTSPlanned,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountTSSpent,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountTSBalance,
                    2
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        
      </TableContainer>




    );
  }, [Records, MessageData.Loading]);
};


const RenderProjectDaysLedger = ({
  Records,
  MessageData 
}) => {
  return React.useMemo(() => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={12}
                align="center"
                className="tablecell header"
              >
                <Typography variant="h5" align="center">
                  Project Days  
                </Typography>
              </TableCell>
            </TableRow>
 
            <TableRow>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                Planned
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                TS Reported
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                Days Balance
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow
                key={row.AlocationID}
                className="table-row"> 
                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.DaysTSPlanned,
                    1
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.DaysTSSpent,
                    1
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.DaysTSBalance,
                    1
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records, MessageData.Loading]);
};


const RenderBudgetLedger = ({ Records }) => {
  return React.useMemo(() => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={10}
                align="center"
                className="tablecell header"
              >
                <Typography variant="h5" align="center">
                  Budget Planning
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                colSpan={4}
                align="center"
                className="tablecell header"
              >
                <Typography variant="h5" align="center">
                  Planned
                </Typography>
              </TableCell>
              <TableCell
                colSpan={3}
                align="center"
                className="tablecell header"
              >
                <Typography variant="h5" align="center">
                  Reported
                </Typography>
              </TableCell>              
            </TableRow>


            <TableRow>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Budget Name
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Planned
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Allocated
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Planned
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  TS Reported
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance
                </Typography>
              </TableCell>              
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow
                key={row.AlocationID}
                className="table-row"> 
                <TableCell align="left" className="tablecell">
                {row.ProjectID > 0 ? <Link
                    sx={{ mr: 1.5 }}
                    href={enumRoutes.Timesheet_FinAllocation_EditWithArgs.replace(
                      ":ProjectID",
                      row.ProjectID
                    ).replace(
                      ":BudgetID",
                      row.BudgetID
                    ).replace(
                      ":StaffID",
                      0
                    )}
                    underline="none"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {row.BudgetName}
                  </Link> 
                  :
                  row.BudgetName
                  }
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountBalancePlanned,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountBalanceAllocated,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountBalance,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountTSPlanned,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountTSSpent,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountTSBalance,
                    2
                  )}
                </TableCell>                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};

const RenderAllocationLedger = ({ Records }) => {
  return React.useMemo(() => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={11} align="center" className="tablecell">
                <Typography variant="h5" align="center">
                  Allocation Planning
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Budget Name
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Staff Name
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Budget Planned
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Allocated
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Running Balance
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow key={row.AlocationID} className="table-row">
                <TableCell align="left" className="tablecell">
                  {row.AllocationName === "" ? row.BudgetName : ""}
                </TableCell>

                <TableCell align="left" className="tablecell">
                <Link
                    sx={{ mr: 1.5 }}
                    href={enumRoutes.Timesheet_FinAllocation_EditWithArgs.replace(
                      ":ProjectID",
                      row.ProjectID
                    ).replace(
                      ":BudgetID",
                      row.BudgetID
                    ).replace(
                      ":StaffID",
                      row.StaffID
                    )}
                    underline="none"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {row.AllocationName}
                  </Link> 
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountBalancePlanned,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountBalanceAllocated,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountBalance,
                    2
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};


const RenderReportedLedger = ({ Records }) => {
  return React.useMemo(() => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={11} align="center" className="tablecell">
                <Typography variant="h5" align="center">
                  Reported Cost
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Budget Name
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Staff Name
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Budget Planned
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Allocated
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Running Balance
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow key={row.AlocationID} className="table-row">
                <TableCell align="left" className="tablecell">
                  {row.AllocationName === "" ? row.BudgetName : ""}
                </TableCell>

                <TableCell align="left" className="tablecell">
                <Link
                  sx={{ mr: 1.5 }}
                  href={enumRoutes.Timesheet_FinTime_DetailPreview.replace(
                    ":SummaryID",
                    row.TimeSummaryID
                  )}
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  
                  {row.TimeSummaryName}
                </Link>
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountBalancePlanned,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountTSSpent,
                    2
                  )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    row.AmountTSBalance,
                    2
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};


