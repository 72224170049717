import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { RestHelper } from "../helpers/RestHelper";
import { enumLocalStorageArgs } from "../helpers/common.constants";

export class PayrollService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Search(payload: any) {
    const url: string = "Payroll/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
  
  async Manage(payload: any) {
    const url: string = "Payroll/Manage";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
  

  async EmployeeOverview(payload: any) {
    const url: string = "Payroll/EmployeeOverview";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
  
  
  async Submit(payload: any) {
    const url: string = "Payroll/Submit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }


  async Export(payload: any) {
    const url: string = "Payroll/Export";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Save(payload: any) {
    const url: string = "Payroll/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Unlock(payload: any) {
    const url: string = "Payroll/Unlock";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Verify(payload: any) {
    const url: string = "Payroll/Verify";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetExchangeRates(payload: any) {
    const url: string = "Payroll/GetExchangeRatesPerYearMonth";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
