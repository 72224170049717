// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumData,
  enumLocalStorageArgs,
  enumLookupTypes,
  enumPage,
  enumPagination,
  enumRoutes,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Box,
} from "@mui/material";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DownloadActions from "../../actions/DownloadActions";
import MyDialogYesNo from "../../controls/MyDialogYesNo";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import FinBudgetActions from "../../actions/FinBudgetActions";
import { Padding } from "@mui/icons-material";
import MyCurrency from "../../controls/MyCurrency";
import ZCurrencyField from "../../controls/ZCurrencyField";
import ZDate from "../../controls/ZDate";
import ZDropDown from "../../controls/ZDropDown";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import moment from "moment";
import ZTextField from "../../controls/ZTextField";
import FinHeaderButtons from "./FinHeaderButtons";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FinProjectHeader from "./FinProjectHeader";

const RecordDefault = {
  OfficeID: 1445,
  CountryIDs: "",
  Name: "",
  PhaseIDs: "",
  ActiveFL: "1",
  TypeOfContractID: 0,
  ExpirationContractDays: 0,

  //Pagination
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0,
  Year: 2024,
  Month: 1,
  Lock: false,
  OfficeName: "",
  VerifierName: "", //Verifier
};

const styles = {
  TDSmall: { mt: 0, mb: 0, pt: 0, pb: 0 },
};

export default function FinBudgetManage() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Project, setProject] = useState({});
  const [Records, setRecords] = useState([]);
  const [RecordsSum, setRecordsSum] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsPaginationChange, setPaginationChange] = useState(false);
  const [IsDoSearch, setIsDoSearch] = useState(false);
  const [IsLocked, setIsLocked] = useState(false);
  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [ModalMessage, setModalMessage] = useState("");
  const [LookupData, setLookupData] = useState([]);
  const [OfficeData, setOfficeData] = useState([]);
  const [CurrencyExcRates, setCurrencyExcRates] = useState([]);
  const { ProjectID } = useParams();
  const { GoToField, setGoToField } = useParams();
  const [RecordToDelete, setRecordToDelete] = useState(RecordDefault);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);

  // //3 --> Pagination Change
  useEffect(() => {
    if (IsPaginationChange) {
      searchData();
    }
  }, [IsPaginationChange]);

  //4 --> Search Click
  useEffect(() => {
    if (IsDoSearch) {
      searchData();
    }
  }, [IsDoSearch]);


  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const initData = () => {
    async function fetchData() {
      //Clean all statesLock      setIsInitDone(false);

      //Najprvo vidi dali doaga od payroll_verify
      let isVerifyLink =
        location.pathname
          .toLowerCase()
          .toString()
          .indexOf("finbudget_verify".toLowerCase()) > -1;

      RecordDefault.fbProjectID = parseInt(ProjectID); //Mu treba za Edit Project
      RecordDefault.ProjectID = RecordDefault.fbProjectID; //Mu treba za Budget
      setRecord(RecordDefault);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let payload = { fpProjectID: ProjectID };

      let responses = await new FinBudgetActions().InitAsync(payload); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        let projectData = responses[1].data;

        setLookupData(lookupData);
        setProject(projectData);

        setIsLocked(projectData.PhaseLockFL);

        //setYearsData(yearsData);
        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const searchData = () => {
    searchDataAsync();
  };

  async function searchDataAsync() {
    //Load Start
    setRecords([]);
    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);

    let responses = await new FinBudgetActions().GetByProjectIDAsync(Record);
    if (!responses.error) {
      let records = responses[0].data;

      //For Pagination purposes
      const pageIndex = responses[0].headers["content-pageindex"];
      const totalRecords = responses[0].headers["content-totalrecords"];
      const totalPages = responses[0].headers["content-totalpages"];
      setRecord((a) => ({
        ...a,
        ["PageIndex"]: pageIndex,
        ["TotalRecords"]: totalRecords,
        ["TotalPages"]: totalPages,
      }));
      setPaginationChange(false); //za da se pali na sleden page
      setIsDoSearch(false); //za da se pali na Search Click 

      setRecords(records);
      makeSumRecord(records);

      //Load Finished
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    } else {
      //Load with Error
      setIsDoSearch(false); //za da se pali na Search Click
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }

  const makeSumRecord = (currentRecords) => {
    let newSumRecord = {};

    newSumRecord.fbMgmtYearlyFee = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.fbMgmtYearlyFee)),
      0
    );
    newSumRecord.fbAmount = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.fbAmount)),
      0
    );
    setRecordsSum(newSumRecord);
  };


  const handleSaveRows = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(
        enumPage.LoadStartWithMsg,
        null,
        "This process could take a while, please wait!"
      );

      setMessageData(result);

      let response = await new FinBudgetActions().SaveAsync(Records);
      if (!response.error) {
        await searchDataAsync();

        result = msgHelper.setPhase(enumPage.SaveSuccess);
        setMessageData(result);
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };

  


  const handleChangeSearch = (e) => {
    const { name, value, text } = e.target;
    console.log(name + ":" + value);
    //setRecord((prevState) => ({ ...prevState, [name]: value, ["PageIndex"]: 0 }));

    setRecord((a) => ({ ...a, [name]: value }));
  };

  const handleSearch = (e) => {
    setRecord((a) => ({ ...a, ["PageIndex"]: 0 }));
    setIsDoSearch(true);
  };

  const handleExternalChangeRecord = (e) => {
    //alert("handleExternalChangeRecord");
    console.error("CALLED handleExternalChangeRecord");
    const { name, value, primaryKey, text } = e.target; //vo name-ot go imame proektot
    console.error(name + ":" + value + ":" + primaryKey);

    //najdi go od nizata samiot record i updatetiraj go
    let newRecords = [...Records]; //Records.map( x => ({...x}) )
    let currentCheck = newRecords.find(
      (a) => a.RowNo.toString() === primaryKey.toString()
    );

    if (name === "fbAmount") {
      currentCheck["fbMgmtFee"] = 0;
      currentCheck["fbMgmtYearlyFee"] = 0;
      currentCheck["fbMgmtYearlyDays"] = 0;
    } 
    else if (name === "fbMgmtFee") {
      currentCheck["fbAmount"] = 0;
    }
    else if (name === "fbMgmtYearlyFee") {
      currentCheck["fbAmount"] = 0;
    }
    

    //za vo koj red od nizata da go setira so findInex
    let propertyIndex = newRecords.findIndex(
      (x) => x.RowNo.toString() === primaryKey.toString()
    );
    newRecords[propertyIndex] = currentCheck;
    setRecords(newRecords);
  };

  const handleChangeRecord = (e) => {
    console.error("CALLED handleChangeRecord");
    const { name, value, primaryKey, text } = e.target; //vo name-ot go imame proektot
    console.error(name + ":" + value + ":" + primaryKey);

    //najdi go od nizata samiot record i updatetiraj go
    let newRecords = [...Records]; //Records.map( x => ({...x}) )
    let currentCheck = newRecords.find(
      (a) => a.RowNo.toString() === primaryKey.toString()
    );
    currentCheck[name] = value;
    currentCheck["IsChanged"] = true;

    //za vo koj red od nizata da go setira so findInex
    let propertyIndex = newRecords.findIndex(
      (x) => x.RowNo.toString() === primaryKey.toString()
    );
    newRecords[propertyIndex] = currentCheck;
    setRecords(newRecords);
    console.error(" FINISHED handleChangeRecord");
  };

  const handleModalOpenForDelete = (row) => {
    setRecordToDelete(row);
    setIsModalOpen(true); //Otvori popup
  };


  const handleModalCloseForDelete = (IsOk) => {
    if (IsOk) {
      async function fetchData() {
        let result = msgHelper.setPhase(enumPage.LoadStart);
        setMessageData(result);

        let response = await new FinBudgetActions().DeleteAsync(
          RecordToDelete
        );
        if (!response.error) {
          await searchDataAsync();

          result = msgHelper.setPhase(enumPage.DeleteSuccess);
          setMessageData(result);
        }
      }

      fetchData();
    }
    setIsModalOpen(false);
  };

  const handleExcelExport = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStartWithAwait);
      setMessageData(result);

      let responses = await new FinBudgetActions().ExportAsync(Record);

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(
          payload
        );

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <FinProjectHeader />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5}>
              <MyText
                Id="Name"
                PlaceHolder="Search for Budget Line"
                Record={Record}
                HandleChange={handleChangeSearch}
                ValidationMessages={Record.ValidationMessages}
                Required={true}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                color="success"
                size="small"
                disabled={MessageData.Loading}
                onClick={() => handleSearch()}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="subtitle1" align="left">
                Total Records found: {Record.TotalRecords}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle1" align="left">
                {Record.VerifierName !== "" &&
                  "Verifier for Office:" +
                  Record.OfficeName +
                  " is " +
                  Record.VerifierName}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <FinHeaderButtons />

      <RenderTable
        RecordsSum={RecordsSum}
        Records={Records}
        Record={Record}
        onHandleChange={handleChangeRecord}
        onExternalHandleChange={handleExternalChangeRecord}
        onHandleSave={handleSaveRows}
        onDeleteRow={handleModalOpenForDelete}
        MessageData={MessageData}
        DataArray={[LookupData]}
        IsLocked={IsLocked}
        onExportExcel={handleExcelExport}
      />

      <MyDialogYesNo
        IsOpen={IsModalOpen}
        HandleModalClose={handleModalCloseForDelete}
        Title={enumSharedMsgs.WariningMsg}
        Content={"Are you sure you want to delete this budget line?"}
      />


    </Typography>
  );
}

const RenderTable = ({
  RecordsSum,
  Records,
  Record,
  DataArray,
  onHandleChange,
  onAddRow,
  onExternalHandleChange,
  onHandleFocus,
  onHandleSave,
  MessageData,
  IsLocked,
  onExportExcel,
  onDeleteRow,
}) => {
  //Record = Pagination
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer
        sx={{ display: { xs: "block", sm: "block", md: "block" } }}
      >
        <Table
          sx={{ minWidth: 650, border: "1" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={20} align="center">
               
                <Button
                  sx={{ mr: 2, mb: 1 }}
                  variant="contained"
                  startIcon={<FileDownloadIcon />}
                  size="small"
                  color="warning"
                  disabled={MessageData.Loading}
                  onClick={() => onExportExcel()}
                >
                  Export Excel
                </Button>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  #
                </Typography>
              </TableCell>

              <TableCell>
                <Typography variant="h6" align="center">
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Description
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Spark Amount 
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Mngmt Fee Amount
                </Typography>
              </TableCell>              
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Mngmt Fee per Day
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "5%" }}>
                <Typography variant="h6" align="center">
                  &nbsp;
                </Typography>
              </TableCell>
            </TableRow>

            {Records.length > 0 && (
              <TableRow>
                <TableCell colSpan={7} align="right" sx={{ pr: 2 }}>
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={MessageData.Loading || IsLocked}
                    onClick={() => onHandleSave()}
                  >
                    Save All
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <RenderRow
                row={row}
                index={index}
                onHandleChange={onHandleChange}
                onDeleteRow={onDeleteRow}
                onExternalHandleChange={onExternalHandleChange}
                //onHandleFocus={onHandleFocus}
                //onHandleDelete={onHandleDelete}
                MessageData={MessageData}
                IsLocked={IsLocked}
              />
            ))}
          </TableBody>

          {/* PAGINATION FOOTER HERE */}
          {Records.length > 0 && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3} align="right"/>

                <TableCell align="right">
                {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.fbAmount,
                    0
                  )}
                </TableCell>
                <TableCell align="right">
                {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.fbMgmtYearlyFee,
                    0
                  )}
                </TableCell>  
                <TableCell align="right">
                {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.fbAmount+RecordsSum.fbMgmtYearlyFee,
                    0
                  )}
                </TableCell>                         
                <TableCell colSpan={2}/>                     
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} align="right">
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={MessageData.Loading || IsLocked}
                    onClick={() => onHandleSave()}
                  >
                    Save All
                  </Button>
                </TableCell>
                
              </TableRow> 
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    );
  }, [Records, MessageData]);
};

const RenderRow = ({
  row,
  index,
  onHandleChange,
  onExternalHandleChange,
  onDeleteRow,
  MessageData,
  IsLocked
}) => {
  //Record = Pagination

  return React.useMemo(() => {
    return (
      <TableRow
        key={row.RowNo}

      //sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell sx={[styles.TDSmall, { width: "3%" }]}>
          {index + 1}.  
        </TableCell>

        <TableCell sx={[styles.TDSmall]}>
          <ZTextField
            Id="fbName"
            Value={row.fbName}
            HandleChange={onHandleChange}
            PrimaryKey={row.RowNo}
            Disabled={row.IsLocked || IsLocked}
          />
        </TableCell>

        <TableCell sx={[styles.TDSmall]}>
          <ZTextField
            Id="fbDescription"
            Value={row.fbDescription}
            HandleChange={onHandleChange}
            PrimaryKey={row.RowNo}
            Disabled={row.IsLocked || IsLocked}
          />
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          <ZCurrencyField
            Id="fbAmount"
            Value={row.fbAmount}
            HandleChange={onHandleChange}
            ExternalHandleChange={onExternalHandleChange}
            PrimaryKey={row.RowNo}
            Disabled={row.IsLocked || IsLocked}
          />
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          <ZCurrencyField
            Id="fbMgmtYearlyFee"
            Value={row.fbMgmtYearlyFee}
            HandleChange={onHandleChange}
            ExternalHandleChange={onExternalHandleChange}
            PrimaryKey={row.RowNo}
            Disabled={row.IsLocked || IsLocked}
          />
        </TableCell>


        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          <ZCurrencyField
            Id="fbMgmtFee"
            Value={row.fbMgmtFee}
            HandleChange={onHandleChange}
            ExternalHandleChange={onExternalHandleChange}
            PrimaryKey={row.RowNo}
            Disabled={row.IsLocked || IsLocked}
          />
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {row.fbBudgetID > 0 && (
            <Button
              //sx={{ mt: 1 }}
              variant="contained"
              color="error"
              size="small"
              onClick={() => onDeleteRow(row)}
              disabled={row.IsLocked || IsLocked}
            >
              Delete
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  }, [
    row.RowNo,
    row.fbBudgetID,
    row.fbName,
    row.fbDescription,
    row.fbMgmtFee,
    row.fbAmount,
    row.fbMgmtYearlyFee
  ]);
};
