import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { RestHelper } from "../helpers/RestHelper";
import { enumLocalStorageArgs } from "../helpers/common.constants";

export class FinProjectService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Search(payload: any) {
    const url: string = "FinProject/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Save(payload: any) {
    const url: string = "FinProject/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Edit(payload: any) {
    const url: string = "FinProject/Edit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async CreateAndEdit(payload: any) {
    const url: string = "FinProject/CreateAndEdit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async LockUnlock(payload: any) {
    const url: string = "FinProject/LockUnlock";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Revision(payload: any) {
    console.log(payload);
    const url: string = "FinProject/Revision";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetLedgerByBudget(payload: any) {
    console.log(payload);
    const url: string = "FinProject/GetLedgerByBudget";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetLedgerByProject(payload: any) {
    console.log(payload);
    const url: string = "FinProject/GetLedgerByProject";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetLedgerByAllocation(payload: any) {
    console.log(payload);
    const url: string = "FinProject/GetLedgerByAllocation";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Submit(payload: any) {
    console.log(payload);
    const url: string = "FinProject/Submit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Verify(payload: any) {
    console.log(payload);
    const url: string = "FinProject/Verify";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetVersions(payload: any) {
    console.log(payload);
    const url: string = "FinProject/GetVersions";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Export(payload: any) {
    const url: string = "FinProject/Export";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async ExportLedgerProject(payload: any) {
    console.log("LEONEEE");
    console.log(payload);
    const url: string = "FinProject/ExportLedgerProject";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async ExportLedgerBudget(payload: any) {
    const url: string = "FinProject/ExportLedgerBudget";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async ExportLedgerAllocation(payload: any) {
    const url: string = "FinProject/ExportLedgerAllocation";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
