// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumData,
  enumLocalStorageArgs,
  enumLookupTypes,
  enumPage,
  enumPagination,
  enumRoutes
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow, Tooltip
} from "@mui/material";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DownloadActions from "../../actions/DownloadActions";
import FinEmployeeActions from "../../actions/FinEmployeeActions";
import CurrencyHelper from "../../helpers/CurrencyHelper";

const RecordDefault = {
  OfficeID: 0,
  CountryIDs: "",
  Name: "",
  PhaseIDs: "",
  ActiveFL: "1",
  TypeOfContractID: 0,
  ExpirationContractDays: 0,
  Year: 2025,

  //Pagination
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0
};

export default function FinEmployeeSearch() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsPaginationChange, setPaginationChange] = useState(false);
  const [IsDoSearch, setIsDoSearch] = useState(false);

  const [YesNoAllData, setYesNoAllData] = useState([]);
  const [ContractMonthData, setContractMonthData] = useState([]);

  const [LookupData, setLookupData] = useState([]);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => { initData(); }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => { if (IsInitDone) { searchData(); } }, [IsInitDone]);

  // //3 --> Pagination Change 
  useEffect(() => { if (IsPaginationChange) { searchData(); } }, [IsPaginationChange]);

  //4 --> Search Click
  useEffect(() => { if (IsDoSearch) { searchData(); } }, [IsDoSearch]);


  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Proveri dali slucajno ima vo Local Storage definirani Search Arguments
      let RecordFromStorage = new LocalStorageHelper().GetJSON(enumLocalStorageArgs.FinEmployeeSearch);
      if (RecordFromStorage) {
        RecordFromStorage.PageIndex = 0; //sekogas prviot page pokazi go 
        setRecord(RecordFromStorage);
      }

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new EmployeeActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        let yesNoAllData = responses[1].data;
        let contractMonthData = responses[3].data;

        setLookupData(lookupData);
        setYesNoAllData(yesNoAllData);
        setContractMonthData(contractMonthData);

        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const searchData = () => {
    async function fetchData() {
      //Load Start
      setRecords([]);
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new FinEmployeeActions().SearchEmployeesCoverAsync(Record);
      if (!responses.error) {
        const records = responses[0].data;

        //For Pagination purposes
        const pageIndex = responses[0].headers["content-pageindex"];
        const totalRecords = responses[0].headers["content-totalrecords"];
        const totalPages = responses[0].headers["content-totalpages"];
        setRecord((a) => ({ ...a, ["PageIndex"]: pageIndex, ["TotalRecords"]: totalRecords, ["TotalPages"]: totalPages }));
        setPaginationChange(false); //za da se pali na sleden page
        setIsDoSearch(false);       //za da se pali na Search Click


        setRecords(records);

        //setiraj JSON vo Local Storage za da citas posle od tamu na sleden comeback 
        new LocalStorageHelper().SetJSON(enumLocalStorageArgs.FinEmployeeSearch, Record);


        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const exportData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new EmployeeActions().ExportAsync(Record);

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE 
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(payload);

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSearch = (e) => {
    setRecord((a) => ({ ...a, ["PageIndex"]: 0 }));
    setIsDoSearch(true);
  };


  const handlePageIndexChange = (value) => {
    if (value < 0) value = 0;
    if (value >= Record.TotalPages) value = Record.TotalPages - 1;
    setRecord((a) => ({ ...a, ["PageIndex"]: parseInt(value) }));
    setPaginationChange(true); //ke povika gore HOOK za da se refreshira Search-ot
  };


  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ToolbarButtons = () => {
    return (
      <React.Fragment>
        <Button
          sx={{ ml: 3, mr: 2, mt: 3.5 }}
          variant="contained"
          color="success"
          size="small"
          disabled={MessageData.Loading}
          onClick={() => handleSearch()}
        >
          Search
        </Button>

      </React.Fragment>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />


      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={1}>
              <MyDropDown
                Id="Year"
                Record={Record}
                PlaceHolder="Year"
                HandleChange={handleChange}
                Options={enumData.FINYEARS}
              />
            </Grid>
            
            <Grid item xs={12} sm={12} md={2}>
              <MyText
                Id="Name"
                PlaceHolder="Name or Email"
                Record={Record}
                HandleChange={handleChange}
                ValidationMessages={Record.ValidationMessages}
                Required={true}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="OfficeID"
                Record={Record}
                PlaceHolder="Office"
                HandleChange={handleChange}
                Options={LookupData}
                LookupTypeId={enumLookupTypes.Offices}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={1}>
              <MyDropDown
                Id="ActiveFL"
                Record={Record}
                PlaceHolder="Active"
                HandleChange={handleChange}
                Options={YesNoAllData}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="TypeOfContractID"
                Record={Record}
                PlaceHolder="Type of Contract"
                HandleChange={handleChange}
                Options={LookupData}
                LookupTypeId={enumLookupTypes.Contract_Type}
              />
            </Grid>
{/* 
            <Grid item xs={12} sm={12} md={1}>
              <MyDropDown
                Id="ExpirationContractDays"
                Record={Record}
                PlaceHolder="Expiration Days"
                HandleChange={handleChange}
                Options={ContractMonthData}
              />
            </Grid> */}

            <Grid justifyContent="flex-end">
              <ToolbarButtons />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="left">
                Total Records found: {Record.TotalRecords}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <RenderTable Records={Records} Record={Record} onPageIndexChange={handlePageIndexChange} />
    </Typography>
  );
}

const RenderTable = ({ Records, Record, onPageIndexChange }) => { //Record = Pagination
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className="tablecell header" rowSpan={2}>
                <Typography variant="h4" align="center">
                  Employee Name
                </Typography>
              </TableCell>

              <TableCell colSpan={3} align="center" className="tablecell header">
                <Typography variant="h4" align="center">
                  Planned Days
                </Typography>
              </TableCell>  

              <TableCell colSpan={3} align="center" className="tablecell header">
                <Typography variant="h4" align="center">
                  Planned Costs
                </Typography>
              </TableCell>

              <TableCell colSpan={3} align="center" className="tablecell header">
                <Typography variant="h4" align="center">
                  Reported Days
                </Typography>
              </TableCell>  

              <TableCell colSpan={3} align="center" className="tablecell header">
                <Typography variant="h4" align="center">
                  Reported Costs
                </Typography>
              </TableCell>                

              <TableCell className="tablecell header" rowSpan={2}></TableCell>
            </TableRow>

            <TableRow>

            <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Planned Days
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Contract Days
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance Days
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Planned Alloc.
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Contract Salary
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance Amount
                </Typography>
              </TableCell>

              {/* TIMESHEET CELLS */}
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Planned Days
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Reported Days
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance Days
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Reported TS Fees
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Paid Salary
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance Amount
                </Typography>
              </TableCell>

            </TableRow>            
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow
                key={row.eyEmployeeID}
                className="table-row"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="tablecell">
                  {Record.PageIndex * Record.PageSize + index + 1}.
                  <Link
                    href={enumRoutes.Timesheet_FinEmployee_Planning.replace(
                      ":EmployeeID",
                      row.eyEmployeeID
                    ).replace(
                      ":Year",
                      Record.Year
                    )}
                    underline="none"
                    rel="noopener noreferrer" 
                    target="_blank"
                  >
                    {row.eyFirstName} {row.eyLastName} <br/>
                    {row.eyEmail}<br/>
                    {row.eyOfficeName}<br/>
                    Active:{row.eyRemoved == 1 ? "No" : "Yes"}
                  </Link>
                </TableCell>
 
                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.DaysPlanned, 1)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.DaysAllocated, 1)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.DaysBalance, 1)}
                </TableCell>
               
                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalancePlanned, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalanceAllocated, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalance, 2)}
                </TableCell>

                {/* TIMESHEET  */}
                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.DaysTSPlanned, 1)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.DaysTSSpent, 1)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.DaysTSBalance, 1)}
                </TableCell>
               
                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountTSPlanned, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountTSSpent, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountTSBalance, 2)}
                </TableCell>


                <TableCell className="tablecell" align="center">

                <Link mr={1}
                    href={      enumRoutes.Employee_Edit.replace(
                      ":EmployeeID",
                      row.eyEmployeeID
                    )}
                    underline="none"
                    rel="noopener noreferrer" 
                  >
                    Profile
                  </Link>

                  |    
                  <Link ml={1}
                    href={enumRoutes.Employee_Contract_Get.replace(
                      ":EmployeeID",
                      row.eyEmployeeID
                    )}
                    underline="none"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Contracts 
                  </Link>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>


          {/* PAGINATION FOOTER HERE */}
          <TableFooter>
            <TableRow>

              <TableCell colSpan={10} align="center">

                <IconButton size="small" name="details"
                  onClick={() => onPageIndexChange(parseInt(Record.PageIndex) - 1)}
                >
                  <ArrowLeftIcon sx={{ fontSize: 20 }} />
                </IconButton>

                <IconButton
                  size="small"
                  name="details"
                >
                  <Typography variant="h4" align="left">Page {parseInt(Record.PageIndex) + 1} of {Record.TotalPages == 0 ? 1 : Record.TotalPages}</Typography>
                </IconButton>

                <IconButton
                  size="small"
                  name="details"
                  onClick={() => onPageIndexChange(parseInt(Record.PageIndex) + 1)}
                >
                  <ArrowRightIcon sx={{ fontSize: 20 }} />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableFooter>

        </Table>
      </TableContainer>
    );
  }, [Records]);
};
 