// @ts-check
import { useEffect } from "react";

import { useState } from "react";
import {
  enumDocumentsSource,
  enumLookupTypes,
  enumPage,
  enumRoutes,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import NotesIcon from "@mui/icons-material/Notes";
import EmployeeActions from "../../actions/EmployeeActions";
import moment from "moment";
import { MessageHelper } from "../../helpers/MessageHelper";
import DownloadActions from "../../actions/DownloadActions";
import SystemLogDetail from "../SystemLog/SystemLogDetail";
import MyDialogYesNo from "../../controls/MyDialogYesNo";
import FinProjectActions from "../../actions/FinProjectActions";
import MyDropDown from "../../controls/MyDropDown";
import MyAlert from "../../controls/MyAlert";

export default function FinHeaderButtons(args) {
  //Page MAIN Hooks
  const [IsNewRecord, setIsNewRecord] = useState(true);
  const [MessageData, setMessageData] = useState({});
  const [Project, setProject] = useState({});
  const [ProjectVersions, setProjectVersions] = useState([]);
  const [IsModalRevisionOpen, setIsModalRevisionOpen] = useState(false);
  const [IsModalVersionOpen, setIsModalVersionOpen] = useState(false);
  const [IsRevisionOpen, setIsRevisionOpen] = useState(false);
  const [IsSubmitOpen, setIsSubmitOpen] = useState(false);


  //Da proveri dali e nov zapis ili postoecki
  const { ProjectID, BudgetID } = useParams();

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    if (ProjectID) {
      if (parseInt(ProjectID) > 0) {
        setIsNewRecord(false);

        initData();
      }
    } else {
      setIsNewRecord(true);
    }
  }, [location.pathname + "/" + location.search]);


  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const handleChangeVersion = (e) => {
    const { name, value } = e.target;
     
    if (parseInt(value) > 0) {
      
      setIsModalVersionOpen(false);

      navigate(
        enumRoutes.Timesheet_FinProject_Preview.replace(
          ":ProjectID",
          value
        )  
      );
    }
  };


  const initData = () => {
    async function fetchData() {

      //sega loadiraj Contracts by employee
      let payload = { fpProjectID: ProjectID };
      let responses = await new FinProjectActions().EditAsync(payload);
      if (!responses.error) {
        //Load Finished
        let projectData = responses[0].data;
        setProject(projectData);

        if (projectData.fpSubmittedFL) {
          setIsRevisionOpen(true);
        }

        if (!projectData.fpSubmittedFL) {
          setIsSubmitOpen(true);
        }

        //now if is space for versioning load this as well
        if (projectData.fpVersionDate.toString()  !== '1900-01-01T00:00:00') {
          responses = await new FinProjectActions().GetVersionsAsync(payload);
          if (!responses.error) {
            //Load Finished
            let projectVersions = responses[0].data;
            setProjectVersions(projectVersions);
          }
          else {
            //Load with Error
            let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
            setMessageData(result);
            return false;
          }
        }
        return true;
      }
      else {
        //Load with Error
        let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
        return false;
      }

    }
    fetchData();
  };

  const handleModalOpenForRevision = () => {
    setIsModalRevisionOpen(true); //Otvori popup
  };



  const handleModalCloseForRevision = (IsOk) => {
    if (IsOk) {
      async function fetchData() {
        let result = msgHelper.setPhase(enumPage.LoadStart);
        setMessageData(result);

        let payload = { fpProjectID: ProjectID };
        console.log(payload);

        let responses = await new FinProjectActions().RevisionAsync(payload);
        if (!responses.error) {
          
          //GeneralVM
          let newProjectData = responses[0].data;
          //REDIRECT TO ALLOCATION PART
          navigate(
            enumRoutes.Timesheet_FinAllocation_Edit.replace(
              ":ProjectID",
              newProjectData.ID
            )
          );
          window.location.reload(); // Force page reload 
        }
        else {
          //Load with Error
          let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
          setMessageData(result);  
        } 
        setIsModalRevisionOpen(false);
        return true;
      }
      
      fetchData();  
    }
    else {
      setIsModalRevisionOpen(false);
    }
  };

  
  //****************************************** */
  //GUI DISPLAY
  //****************************************** */

  return (
    <Grid
      container
      align="left"
      sx={{ borderBottom: 1, borderColor: "grey.500", pb: 1 }}
      align="center"
    >
      <MyAlert {...MessageData} />
      
      
      <Grid item xs={12} spacing={1}>
      <Button
          sx={{ mr: 2, mb: 1 }}
          variant="contained"
          startIcon={<NotesIcon />}
          size="small"
          disabled={MessageData.Loading || IsNewRecord}
          onClick={() =>
            navigate(
              enumRoutes.Timesheet_FinProject_OverviewPlanning.replace(
                ":ProjectID",
                ProjectID
              )
            )
          }
        >
          Project Plan Summary
        </Button>

        <Button
          sx={{ mr: 2, mb: 1 }}
          variant="contained"
          startIcon={<NotesIcon />}
          size="small"
          disabled={MessageData.Loading || IsNewRecord}
          onClick={() =>
            navigate(
              enumRoutes.Timesheet_FinProject_Edit.replace(
                ":ProjectID",
                ProjectID
              )
            )
          }
        >
          Project
        </Button>

        <Button
          sx={{ mr: 2, mb: 1 }}
          variant="contained"
          startIcon={<NotesIcon />}
          size="small"
          disabled={MessageData.Loading || IsNewRecord}
          onClick={() =>
            navigate(
              enumRoutes.Timesheet_FinBudget_Edit.replace(
                ":ProjectID",
                ProjectID
              )
            )
          }
        >
          Budget Lines
        </Button>

        <Button
          sx={{ mr: 2, mb: 1 }}
          variant="contained"
          startIcon={<NotesIcon />}
          size="small"
          disabled={MessageData.Loading || IsNewRecord}
          onClick={() =>
            navigate(
              enumRoutes.Timesheet_FinAllocation_Edit.replace(
                ":ProjectID",
                ProjectID
              )
            )
          }
        >
          Allocations
        </Button>

        <Button
          sx={{ mr: 2, mb: 1 }}
          variant="contained"
          startIcon={<NotesIcon />}
          size="small"
          disabled={MessageData.Loading || IsNewRecord || !IsRevisionOpen} //samo verificiranite proekti moze da im se pravi Revizija
          onClick={() => handleModalOpenForRevision()}
        >
          Revision
        </Button>

        <Button
          sx={{ mr: 2, mb: 1 }}
          variant="contained"
          startIcon={<NotesIcon />}
          size="small"
          disabled={MessageData.Loading || IsNewRecord || !IsSubmitOpen} //samo verificiranite proekti moze da im se pravi Revizija
          onClick={() =>
            navigate(
              enumRoutes.Timesheet_FinProject_Submit.replace(
                ":ProjectID",
                ProjectID
              )
            )
          }
        >
          Submit
        </Button>


        {ProjectVersions.length > 0 &&
          <Button
            sx={{ mr: 2, mb: 1 }}
            variant="contained"
            startIcon={<NotesIcon />}
            size="small"
            //disabled={MessageData.Loading || IsNewRecord || !IsSubmitOpen} //samo verificiranite proekti moze da im se pravi Revizija
            onClick={() => setIsModalVersionOpen(true)}
          >
            Versions
          </Button>


        }
      </Grid>
      <MyDialogYesNo
        IsOpen={IsModalRevisionOpen}
        HandleModalClose={handleModalCloseForRevision}
        Title={enumSharedMsgs.WariningMsg}
        Content={
          "Are you sure you want to revise this project, it will be unlocked for changes and verifiers will be informed for this change?"
        }
      />

      <Dialog open={IsModalVersionOpen}  sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "250px",  // Set your width here
        },
      },
    }}>
        <DialogTitle>Versions</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <MyDropDown
              Id="fpProjectID"
              Record={Project}
              PlaceHolder="Choose Version"
              HandleChange={handleChangeVersion}
              Options={ProjectVersions}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => setIsModalVersionOpen(false)}>Cancel</Button>
      </DialogActions>
      </Dialog>

    </Grid>
  );
}
