const _moment = require('moment');

interface String {
    WithLeftComma(): string;
    WithSlash(): string;
    bool(): boolean;
    ToNA() : string;
    ToAll() : string;
    ToInt() : number;
    ToDraft(): string;
    ToDateOrStringEmpty() : string;
    ToMonthName() : string;
}


String.prototype.WithLeftComma = function (): string {
    
    let value = this;

    if (value)
    {
        if (value.trim() !== '')
        {
            return ', ' + value;
        }
    }
    return value.toString();
}


String.prototype.ToInt = function (): number {
    
    let value : string = this as string;

    if (value)
    {
        if (value.trim() !== '')
        {
            return parseInt(value);
        }
    }
    return 0;
}


String.prototype.WithSlash = function (): string {
    
    let value = this;

    if (value)
    {
        if (value.trim() !== '')
        {
            return '/ ' + value;
        }
    }
    return value.toString();
}


String.prototype.ToNA = function (): string {
    
    let value = this;

    if (value)
    {
        if (value.trim() !== '')
        {
            return value.toString();
        }
    }
    return "N/A";
}

String.prototype.ToDraft = function (): string {
    
    let value = this.toString();

    if (value === "false")
    {
        return "DRAFT"
    }
    return "AHHHHHHH";
}

String.prototype.ToAll = function (): string {
    
    let value = this;

    if (value)
    {
        if (value.trim() !== '')
        {
            return value.toString();
        }
    }
    return "All";
}


String.prototype.ToDateOrStringEmpty = function (): string {
    
    let value = this;

    if (value)
    {
        if (_moment(value).format("YYYY") == '1900')
        {
            return ""; 
        }
    }
    return _moment(value).format("DD/MM/YYYY")
}

String.prototype.bool = function (): boolean {
    return this.toLowerCase() == 'true';
};

String.prototype.ToMonthName = function (): string {
    
    let value = this;

    if (value)
    {
        let monthNumber : number = parseInt(value.toString());

        if (monthNumber >= 1 && monthNumber <= 12) {
            const date = new Date(1900, monthNumber - 1, 1);
            return date.toLocaleString('default', { month: 'long' });
        }
        
    }
    return 'Invalid Month';
}