// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumData,
  enumLocalStorageArgs,
  enumLookupTypes,
  enumPage,
  enumPagination,
  enumRoutes,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Box,
} from "@mui/material";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DownloadActions from "../../actions/DownloadActions";
import MyDialogYesNo from "../../controls/MyDialogYesNo";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import PayrollActions from "../../actions/PayrollActions";
import { Padding } from "@mui/icons-material";
import MyCurrency from "../../controls/MyCurrency";
import ZCurrencyField from "../../controls/ZCurrencyField";
import ZDate from "../../controls/ZDate";
import ZDropDown from "../../controls/ZDropDown";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import moment from "moment";

const RecordDefault = {
  OfficeID: 1445,
  CountryIDs: "",
  Name: "",
  PhaseIDs: "",
  ActiveFL: "1",
  TypeOfContractID: 0,
  ExpirationContractDays: 0,

  //Pagination
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0,
  Year: 2024,
  Month: 1,
  Lock: false,
  OfficeName: "",
  VerifierName: "", //Verifier
};

const styles = {
  TDSmall: { mt: 0, mb: 0, pt: 0, pb: 0 },
};

export default function PayrollManage() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [RecordsSum, setRecordsSum] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsPaginationChange, setPaginationChange] = useState(false);
  const [IsDoSearch, setIsDoSearch] = useState(false);
  const [IsLocked, setIsLocked] = useState(false);
  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [ModalMessage, setModalMessage] = useState("");
  const [LookupData, setLookupData] = useState([]);
  const [OfficeData, setOfficeData] = useState([]);
  const [CurrencyExcRates, setCurrencyExcRates] = useState([]);
  const { OfficeID, Year, Month } = useParams(); //Timesheet_Payroll_Verify: "/Timesheet/payroll_verify/OfficeID/Year/Month", //OfficeID/Year/month

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);

  // //3 --> Pagination Change
  useEffect(() => {
    if (IsPaginationChange) {
      searchData();
    }
  }, [IsPaginationChange]);

  //4 --> Search Click
  useEffect(() => {
    if (IsDoSearch) {
      searchData();
    }
  }, [IsDoSearch]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const initData = () => {
    async function fetchData() {
      //Clean all statesLock      setIsInitDone(false);

      //Najprvo vidi dali doaga od payroll_verify
      setRecord(RecordDefault);

      //Proveri dali slucajno ima vo Local Storage definirani Search Arguments
      let RecordFromStorage = new LocalStorageHelper().GetJSON(
        enumLocalStorageArgs.PayrollManage
      );
      if (RecordFromStorage) {
        RecordFromStorage.PageIndex = 0; //sekogas prviot page pokazi go
        setRecord(RecordFromStorage);
      }

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new PayrollActions().InitAsync(RecordDefault); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        let officeData = responses[1].data;

        setLookupData(lookupData);

        setOfficeData(officeData);

        //setYearsData(yearsData);
        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const searchData = () => {
    searchDataAsync();
  };

  async function searchDataAsync() {
    //Load Start
    setRecords([]);
    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);

    let responses = await new PayrollActions().ManageAsync(Record);
    if (!responses.error) {
      const records = responses[0].data;

      //setiraj current za daden mesec / godina
      let currencyExchangeRatesData = responses[1].data;
      setCurrencyExcRates(currencyExchangeRatesData);

      //For Pagination purposes
      const pageIndex = responses[0].headers["content-pageindex"];
      const totalRecords = responses[0].headers["content-totalrecords"];
      const totalPages = responses[0].headers["content-totalpages"];
      setRecord((a) => ({
        ...a,
        ["PageIndex"]: pageIndex,
        ["TotalRecords"]: totalRecords,
        ["TotalPages"]: totalPages,
      }));
      setPaginationChange(false); //za da se pali na sleden page
      setIsDoSearch(false); //za da se pali na Search Click

      setRecords(records);
      makeSumRecord(records);

      if (records.length === 0) {
        result = msgHelper.setPhase(
          enumPage.LoadSuccessWithWarningMsg,
          null,
          "No records found"
        );
        setMessageData(result);
        return;
      }

      //odreduva dali ke zakluci cel period i ke napise poraka
      let firstRecordForLockUnlock = records[0];
      let isLocked = firstRecordForLockUnlock.prIsVerified;
      let dataEntryName = firstRecordForLockUnlock.prVerifierFullName;
      let verifierName = firstRecordForLockUnlock.prVerifierFullName2;
      let officeName = firstRecordForLockUnlock.prEmployeeOffice;
      setRecord((a) => ({
        ...a,
        ["VerifierName"]: dataEntryName,
        ["VerifierName2"]: verifierName,
        ["OfficeName"]: officeName,
      }));

      setIsLocked(isLocked);
      if (isLocked) {
        result = msgHelper.setPhase(
          enumPage.LoadSuccessWithWarningMsg,
          null,
          "Period has been verified and can't be edited"
        );

        setMessageData(result);
        setModalMessage(
          "Are you sure you want to unlock period:" +
          Record.Month +
          "/" +
          Record.Year +
          " for " +
          Record.OfficeName +
          "?"
        );
        return;
      } else {
        setModalMessage(
          "Before you submit the payroll for office: " +
          Record.OfficeName +
          ", please ensure that you have saved all data previously on button [Save All], checked all salaries for the period:" +
          Record.Month +
          "/" +
          Record.Year +
          ". If they are correct, then proceed with finalizing it, once you finalize you will be not able to change it?"
        );
      }

      //setiraj JSON vo Local Storage za da citas posle od tamu na sleden comeback
      new LocalStorageHelper().SetJSON(
        enumLocalStorageArgs.PayrollManage,
        Record
      );

      //Load Finished
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    } else {
      //Load with Error
      setIsDoSearch(false); //za da se pali na Search Click
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }

  
  const makeSumRecord = (currentRecords) => {
    let newSumRecord = {};

    newSumRecord.prSparkAmount = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.prSparkAmount)),
      0
    );
    
    setRecordsSum(newSumRecord);
  };

  const saveData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(
        enumPage.LoadStartWithMsg,
        null,
        "This process could take a while, please wait!"
      );
      setMessageData(result);

      let response = await new PayrollActions().SaveAsync(Records);
      if (!response.error) {
        await searchDataAsync();

        result = msgHelper.setPhase(enumPage.SaveSuccess);
        setMessageData(result);
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };

  const lockUnlockData = () => {
    setIsModalOpen(true); //Otvori popup
  };

  const handleModalClose = (IsOk) => {
    if (IsOk) {
      async function fetchData() {
        //Load Start
        let result = msgHelper.setPhase(
          enumPage.LoadStartWithMsg,
          null,
          "This process could take a while, please wait!"
        );
        setMessageData(result);


        let response = null
        if (!IsLocked) {
          Record.Lock = true;
          response = await new PayrollActions().SubmitAsync(Record);
        }
        else {
          Record.Lock = false;
          response = await new PayrollActions().UnlockAsync(Record);
        }

        if (!response.error) {
          await searchDataAsync();

          setIsLocked(Record.Lock);

          if (Record.Lock) {

            result = msgHelper.setPhase(
              enumPage.LoadSuccessWithMsg,
              null,
              "Payroll has been submitted for " +
              Record.Month +
              "/" +
              Record.Year +
              " for office:" +
              Record.OfficeName
            );
            setMessageData(result);
          } else {
            result = msgHelper.setPhase(
              enumPage.LoadSuccessWithMsg,
              null,
              "Payroll has been unlocked for edit for " +
              Record.Month +
              "/" +
              Record.Year +
              " for office:" +
              Record.OfficeName
            );
            setMessageData(result);
          }
        } else {
          //Finished  with Error
          result = msgHelper.setPhase(enumPage.ErrorServer, response);
          setMessageData(result);
          setRecord((a) => ({
            ...a,
            ValidationMessages: result.ValidationMessages,
          })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
        }
      }
      fetchData();
    }
    setIsModalOpen(false);
  };

  const handleSearch = (e) => {
    setRecord((a) => ({ ...a, ["PageIndex"]: 0 }));
    setIsDoSearch(true);
  };

  const exportData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new PayrollActions().ExportAsync(Record);

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(
          payload
        );

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const handleChangeSearch = (e) => {
    const { name, value, text } = e.target;
    console.log(name + ":" + value);
    //setRecord((prevState) => ({ ...prevState, [name]: value, ["PageIndex"]: 0 }));

    setRecord((a) => ({ ...a, [name]: value }));

    if (name == "OfficeID") {
      setRecord((a) => ({
        ...a,
        ["OfficeName"]: text,
        ["VerifierName"]: "",
      }));
    }

    if (name == "OfficeID" || name == "Year" || name == "Month") {
      //For Pagination purposes
      const pageIndex = 0;
      const totalRecords = 0;
      const totalPages = 0;

      setRecord((a) => ({
        ...a,
        ["PageIndex"]: pageIndex,
        ["TotalRecords"]: totalRecords,
        ["TotalPages"]: totalPages,
      }));

      setRecords([]);

      if (value > 0) {
        setIsDoSearch(true);
      }
    }
  };

  const handleChangeTransfer = (e) => {
    const { name, value, primaryKey, text } = e.target; //vo name-ot go imame proektot
    console.error(name + ":" + value + ":" + primaryKey);

    //najdi go od nizata samiot record i updatetiraj go
    let newRecords = [...Records]; //Records.map( x => ({...x}) )
    let currentCheck = newRecords.find(
      (a) => a.prPayrollID.toString() === primaryKey.toString()
    );
    currentCheck[name] = value;

    //za da procitame i koj text za Exchance Currency za da prebarame po Exchance Rate
    if (name === "prLocalCurrencyID") {
      currentCheck["prLocalCurrencyName"] = text;
    }

    currentCheck["prExchangeRate"] = 0;
    currentCheck["prSparkAmount"] = 0;

    //based on currency Name find the ExchangeRate and calculuate
    if (currentCheck["prLocalCurrencyName"] !== "") {
      //najdi go rate-ot
      let currentRate = CurrencyExcRates.find(
        (a) =>
          a.cerName.toString() ===
          currentCheck["prLocalCurrencyName"].toString()
      );
      if (currentRate) {
        let localAmount = parseFloat(currentCheck["prLocalAmount"]);
        let exchangeRate = parseFloat(currentRate["cerExchangeRate"]);
        let sparkAmount = localAmount / exchangeRate;
        currentCheck["prExchangeRate"] = exchangeRate;
        currentCheck["prSparkAmount"] = parseFloat(sparkAmount).toFixed(2);
      } else {
        alert("X");
      }
    }

    currentCheck["IsChanged"] = true;

    //za vo koj red od nizata da go setira so findInex
    let propertyIndex = newRecords.findIndex(
      (x) => x.prPayrollID.toString() === primaryKey.toString()
    );
    newRecords[propertyIndex] = currentCheck;
    setRecords(newRecords);
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="Year"
                Record={Record}
                PlaceHolder="Select Year"
                HandleChange={handleChangeSearch}
                Options={enumData.YEARS}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="Month"
                Record={Record}
                PlaceHolder="Select Month"
                HandleChange={handleChangeSearch}
                Options={enumData.MONTHS}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <MyDropDown
                Id="OfficeID"
                Record={Record}
                PlaceHolder="Office"
                HandleChange={handleChangeSearch}
                Options={OfficeData}
                OptionsText="ldValue"
                OptionsValue="ldLookupDataID"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <MyText
                Id="Name"
                PlaceHolder="Name or Email"
                Record={Record}
                HandleChange={handleChangeSearch}
                ValidationMessages={Record.ValidationMessages}
                Required={true}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              display="flex"
              alignItems="center"
            >
              <Button
                sx={{ mt: 1, mr: 1 }}
                variant="contained"
                color="success"
                size="small"
                disabled={MessageData.Loading}
                onClick={() => handleSearch()}
              >
                Search
              </Button>
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                color="secondary"
                size="small"
                disabled={MessageData.Loading}
                onClick={() => exportData()}
              >
                Export
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="subtitle1" align="left">
                Total Records found: {Record.TotalRecords}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle1" align="left">
                {Record.VerifierName !== "" &&
                  "Data Entry for Office:" +
                  Record.OfficeName +
                  " is " +
                  Record.VerifierName + ", Verifier:" + Record.VerifierName2}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <RenderTable
        Records={Records}
        Record={Record}
        onHandleChange={handleChangeTransfer}
        onHandleSave={saveData}
        onHandleLockUnlock={lockUnlockData}
        MessageData={MessageData}
        DataArray={[LookupData]}
        IsLocked={IsLocked}
        RecordsSum={RecordsSum}
      />

      <MyDialogYesNo
        IsOpen={IsModalOpen}
        HandleModalClose={handleModalClose}
        Title={enumSharedMsgs.WariningMsg}
        Content={ModalMessage}
      />
    </Typography>
  );
}

const RenderTable = ({
  Records,
  Record,
  DataArray,
  onHandleChange,
  onHandleSave,
  onHandleLockUnlock,
  MessageData,
  IsLocked,
  RecordsSum
}) => {
  //Record = Pagination
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer
        sx={{ display: { xs: "block", sm: "block", md: "block" } }}
      >
        <Table
          sx={{ minWidth: 650, border: "1" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={8}>
                <Typography variant="h3" align="center">
                  Payroll for {moment(new Date(Record.Year, Record.Month-1, 1)).format("MMMM yyyy") }
                </Typography>
              </TableCell>
              </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="h6" align="center">
                  Employee Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Email / Office / FTE
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Period / Type of Sallary 
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Local Amount
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Local Currency
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Exch.Rate
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  EUR Amount
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>

            {Records.length > 0 && (
              <TableRow>
                <TableCell colSpan={4} align="left" sx={{ pr: 5 }}>
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="warning"
                    size="small"
                    disabled={MessageData.Loading}
                    onClick={() => onHandleLockUnlock()}
                  >
                    {IsLocked ? "Unlock data" : "Submit Data"}
                  </Button>
                </TableCell>
                <TableCell colSpan={5} align="right" sx={{ pr: 5 }}>
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={MessageData.Loading || IsLocked}
                    onClick={() => onHandleSave()}
                  >
                    Save All
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <RenderRow
                row={row}
                index={index}
                onHandleChange={onHandleChange}
                //onHandleDelete={onHandleDelete}
                MessageData={MessageData}
                DataArray={DataArray}
              />
            ))}
          </TableBody>

          {/* PAGINATION FOOTER HERE */}
          {Records.length > 0 && (
            <TableFooter>
              <TableRow>
              <TableCell colSpan={6}>
                <Typography variant="h6" align="right">
                  Total
                </Typography>
              </TableCell>
              <TableCell  align="right">
                {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.prSparkAmount,
                    2
                  )}
              </TableCell>
            </TableRow>

              <TableRow>
                <TableCell colSpan={2} align="left" sx={{ pr: 5 }}>
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="warning"
                    size="small"
                    disabled={MessageData.Loading}
                    onClick={() => onHandleLockUnlock()}
                  >
                    {IsLocked ? "Unlock data" : "Submit Data"}
                  </Button>
                </TableCell>
                <TableCell colSpan={6} align="right" sx={{ pr: 5 }}>
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={MessageData.Loading || IsLocked}
                    onClick={() => onHandleSave()}
                  >
                    Save All
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    );
  }, [Records, MessageData]);
};

const RenderRow = ({
  row,
  index,
  onHandleChange,
  DataArray,
  onHandleDelete,
  MessageData,
}) => {
  //Record = Pagination

  return React.useMemo(() => {
    return (
      <TableRow
        //style={{ verticalAlign:'top' }}
        key={row.prPayrollID}
        sx={[styles.TDSmall]}

      //sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell sx={{ mt: 0, mb: 0, pt: 0, pb: 0, width: "25%" }}>
          {index + 1}.
          <Link
            href={enumRoutes.Employee_Edit.replace(
              ":EmployeeID",
              row.prEmployeeID
            )}
            underline="none"
            rel="noopener noreferrer"
            target="_blank"
          >
            {row.prEmployeeFullName}
            <br />
          </Link>
          <i>
            Contract period:{moment(row.prContractStart).format("DD/MM/YYYY")} -{" "}
            {moment(row.prContractEnd).format("DD/MM/YYYY")}<br/>
            Contract amount: {row.prContractAmount} {row.prContractCurrencyName}
          </i>
        </TableCell>
        <TableCell sx={{ mt: 0, mb: 0, pt: 0, pb: 0, width: "20%" }}>
          {row.prEmployeeEmail}
          <br />
          {row.prEmployeeOffice}
          <br/>
          FTE: {row.prContractFTE}
        </TableCell>

        <TableCell sx={[styles.TDSmall]}>
        {moment(row.prYear.toString() + "/" + row.prMonth.toString() + "/01").format("MMMM yyyy")}
        <br/>
        <b>{row.TypeTitle}</b>  
        </TableCell>


        <TableCell align="right" sx={[styles.TDSmall]}>
          <ZCurrencyField
            Id="prLocalAmount"
            Value={row.prLocalAmount}
            HandleChange={onHandleChange}
            PrimaryKey={row.prPayrollID}
          />
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall]}>
          <ZDropDown
            Id="prLocalCurrencyID"
            Value={row.prLocalCurrencyID}
            HandleChange={onHandleChange}
            PrimaryKey={row.prPayrollID}
            Options={DataArray[0]}
            LookupTypeId={enumLookupTypes.Contract_Currency}
          />
        </TableCell>
 
        <TableCell sx={[styles.TDSmall]} align="right">
          {new CurrencyHelper().ToMoneyFormatDigits(row.prExchangeRate, 4)}
        </TableCell>

        <TableCell sx={[styles.TDSmall]} align="right">
          {new CurrencyHelper().ToMoneyFormatDigits(row.prSparkAmount, 2)}
        </TableCell>
      </TableRow>
    );
  }, [row.prSparkAmount]);
};
