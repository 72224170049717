import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { RestHelper } from "../helpers/RestHelper";
import { enumLocalStorageArgs } from "../helpers/common.constants";

export class FinEmployeeService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async SearchEmployeesCover(payload: any) {
    const url: string = "FinEmployee/SearchEmployeesCover";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  
  async GetEmployeeCoverByAllocation(payload: any) {
    const url: string = "FinEmployee/GetEmployeeCoverByAllocation";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  
  async GetEmployeeCoverByBudget(payload: any) {
    const url: string = "FinEmployee/GetEmployeeCoverByBudget";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  
  async GetEmployeeCoverByProject(payload: any) {
    const url: string = "FinEmployee/GetEmployeeCoverByProject";
    let response = await this._restHelper.post(url, payload);
    return response;
  }


  async GetEmployeeCoverByEmployee(payload: any) {
    const url: string = "FinEmployee/GetEmployeeCoverByEmployee";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
