// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumPage,
  enumPagination,
  enumRoutes
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import {
  Card, CardContent,
  Grid, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MessageHelper } from "../../helpers/MessageHelper";
import FinTimeActions from "../../actions/FinTimeActions";
import ZCurrencyField from "../../controls/ZCurrencyField";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import FinHeaderButtons from "./FinHeaderButtons";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FinProjectHeader from "./FinProjectHeader";

const RecordDefault = {
  OfficeID: 1445,
  CountryIDs: "",
  Name: "",
  PhaseIDs: "",
  ActiveFL: "1",
  TypeOfContractID: 0,
  ExpirationContractDays: 0,

  //Pagination
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0,
  Year: 2024,
  Month: 1,
  Lock: false,
  OfficeName: "",
  VerifierName: "", //Verifier
};

const styles = {
  TDSmall: { mt: 0, mb: 0, pt: 0, pb: 0 },
};

export default function FinTimeDetailPreview() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});

  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsLocked, setIsLocked] = useState(false);

  const [TimeSummary, setTimeSummary] = useState({});
  const [TimeEntries, setTimeEntries] = useState([]);

  const { SummaryID, showMsg } = useParams();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //za Navigacija
  const navigate = useNavigate();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);


  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const initData = () => {
    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = null;

      //Najprvo vidi dali doaga od payroll_verify
      let isNew =
        location.pathname
          .toLowerCase()
          .toString()
          .indexOf("fintime_new".toLowerCase()) > -1;

      let payload = { ftsTimeSummaryID: SummaryID };
      responses = await new FinTimeActions().EditAsync(payload); // await LookupActions.InitAsync();

      if (!responses.error) {
        let timeData = responses[0].data;

        setTimeSummary(timeData);
        setTimeEntries(timeData.Entries);

        setIsLocked(timeData.ftsSubmittedFL);
        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //sega proveri dali ima nekoja poraka da se napise 
        if (showMsg === "1") {
          let result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Timesheet has been submitted");
          setMessageData(result);
        }
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };


  const handleReopen = () => {
    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);

      setMessageData(result);

      let payload = { ftsTimeSummaryID: SummaryID };
      let response = await new FinTimeActions().ReopenAsync(payload);
      if (!response.error) {
        result = msgHelper.setPhase(enumPage.SaveSuccess);
        setMessageData(result);

        navigate(
          enumRoutes.Timesheet_FinTime_EditAdmin.replace(
            ":SummaryID",
            SummaryID
          ).replace(":showMsg", 2)
        );
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
      }
    }

    fetchData();
  };


  const handleRewrite = () => {
    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);

      setMessageData(result);

      let payload = { ftsTimeSummaryID: SummaryID };
      let response = await new FinTimeActions().RewriteAsync(payload);
      if (!response.error) {
        result = msgHelper.setPhase(enumPage.SaveSuccess);
        setMessageData(result);

        navigate(
          enumRoutes.Timesheet_FinTime_EditAdmin.replace(
            ":SummaryID",
            SummaryID
          ).replace(":showMsg", 1)
        );
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
      }
    }

    fetchData();
  };



  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h3" align="center">
                {TimeSummary.TimesheetTitle}
              </Typography>


            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <RenderTable
        Records={TimeEntries}
        Record={Record}
        MessageData={MessageData}
        IsLocked={IsLocked}
        onReopen={handleReopen}
        onRewrite={handleRewrite}
      />


    </Typography>
  );
}

const RenderTable = ({
  Records,
  Record,
  DataArray,
  onHandleChange,
  onAddRow,
  onExternalHandleChange,
  onHandleFocus,
  onHandleSave,
  MessageData,
  IsLocked,
  onExportExcel,
  onDeleteRow,
  onReopen,
  onRewrite
}) => {
  //Record = Pagination
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer
        sx={{ display: { xs: "block", sm: "block", md: "block" } }}
      >
        <Table
          sx={{ minWidth: 650, border: "1" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
          <TableRow>
              <TableCell colSpan={20} align="center">
                <Button
                  sx={{ mr: 2, mb: 1 }}
                  variant="contained"
                  startIcon={<EditIcon />}
                  size="small"
                  color="warning"
                  disabled={MessageData.Loading}
                  onClick={() => onReopen()}
                >
                  Edit Timesheet 
                </Button>
{/* 
                <Button
                  sx={{ mr: 2, mb: 1 }}
                  variant="contained"
                  startIcon={<FileDownloadIcon />}
                  size="small"
                  color="warning"
                  disabled={MessageData.Loading}
                  onClick={() => onRewrite()}
                >
                  Rewrite Timesheet
                </Button> */}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  #
                </Typography>
              </TableCell>

              <TableCell>
                <Typography variant="h6" align="center">
                  Project
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Cost center
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Allocation (D)
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Reported Days
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  MGMT Fee
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Reported Cost
                </Typography>
              </TableCell>              
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Budget (D)
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Prev. Reported (D)
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Balance (D)
                </Typography>
              </TableCell>

            </TableRow>
 
          </TableHead>
          <TableBody>
            {Records && Records.map((row, index) => (
              <RenderRow
                row={row}
                index={index}
                onHandleChange={onHandleChange}
                onDeleteRow={onDeleteRow}
                onExternalHandleChange={onExternalHandleChange}
                //onHandleFocus={onHandleFocus}
                //onHandleDelete={onHandleDelete}
                MessageData={MessageData}
                IsLocked={IsLocked}
              />
            ))}
          </TableBody>

          {/* PAGINATION FOOTER HERE */}
          {Records.length > 0 && (
            <TableFooter>
              <TableRow>
              <TableCell colSpan={4} align="right" sx={{ pr: 2 }}>
              {new CurrencyHelper().ToMoneyFormatDigits(
                Records.reduce((sum, row) => (sum = sum + parseFloat(row.CalcMonthPlannedPercent)),0)
                , 2)}%
                </TableCell>

                <TableCell colSpan={5}></TableCell> 
              </TableRow>
              
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    );
  }, [MessageData, Records]);
};

const RenderRow = ({
  row,
  index,
  onHandleChange,
  onExternalHandleChange,
  onDeleteRow,
  MessageData,
  IsLocked
}) => {
  //Record = Pagination

  return React.useMemo(() => {
    return (
      <TableRow
        key={row.eyEmployeeID}

      //sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell sx={[styles.TDSmall, { width: "3%" }]}>
          {index + 1}.
        </TableCell>

        <TableCell sx={[styles.TDSmall, { width: "10%" }]}>
          {row.fteProjectName}
        </TableCell>

        <TableCell sx={[styles.TDSmall, { width: "20%" }]}>
          {row.fteBudgetName}
        </TableCell>

        <TableCell sx={[styles.TDSmall]} align="right">
          {new CurrencyHelper().ToMoneyFormatDigits(row.CalcMonthPlannedPercent, 2)}%  
          ({new CurrencyHelper().ToMoneyFormatDigits(row.CalcMonthPlanned, 1)})  
        </TableCell>


        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.fteMonthSpent, 1)}
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.CalcBudgetMgmtFee, 0)}
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.fteCostSpent, 2)}
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.fteMonthSpent, 1)}
        </TableCell>


        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.CalcPrevReportedMonth, 1)}
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.CalcBalanceMonth, 1)}
        </TableCell>


      </TableRow>
    );
  }, [
    MessageData,
    row.fteTimeEntryID,
    row.fteMonthSpent
  ]);
};
