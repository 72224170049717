// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumPage,
  enumRequestHeader,
  enumRoutes,
} from "../../helpers/common.constants";
import ContractActions from "../../actions/ContractActions";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import EmployeeBreadcrumbs from "./EmployeeBreadcrumbs";
import NotesIcon from "@mui/icons-material/Notes";
import BrightnessAutoIcon from "@mui/icons-material/BrightnessAuto";
import { MessageHelper } from "../../helpers/MessageHelper";
import EmployeeActions from "../../actions/EmployeeActions";
import DownloadActions from "../../actions/DownloadActions";
import MyCheckbox from "../../controls/MyCheckbox";
import EmployeeCheckListActions from "../../actions/EmployeeCheckListActions";
import MyCheckboxTable from "../../controls/MyCheckboxTable";
import EmployeeHeaderButtons from "./EmployeeHeaderButtons";

const RecordDefault = {
  ecCheckListID: 0,
  ecEmployeeID: 0,
  ecCheckID: 0,
  ecCreatedBy: "",
  ecCreatedDate: moment(Date.now()).format("YYYY-MM-DD"),
};

export default function EmployeeCheckList() {
  const [Employee, setEmployee] = useState(RecordDefault);
  const [Record, setRecord] = useState(RecordDefault);
  const [InitialRecord, setInitialRecord] = useState(RecordDefault);

  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const { EmployeeID, LookupTypeID } = useParams();
  const [IsInitDone, setIsInitDone] = useState(false);
  const [ForceSave, setForceSave] = useState(false);
  const [IsCalculate, setCalculate] = useState(false);

  //za Navigacija
  const navigate = useNavigate();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Iniicijalno povikaj initData i ako zavrsi se kako sto treba ke go setira IsInitDone: true koj sto ke go zapali hook-ot IsInitDone
  React.useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      GetInfoAsync();
    }
    fetchData();
  };

  async function GetInfoAsync() {
    //sega loadiraj Contracts by employee
    setRecords([]);
    setEmployee({});
    let payload = {
      EmployeeID: EmployeeID,
      eyEmployeeID: EmployeeID,
      TypeID: LookupTypeID,
    }; //mu treba za Employee (eyEmployeeID) i za EmployeeChecklist (EmployeeID)
    let responses = await new EmployeeCheckListActions().InitAsync(payload); // await LookupActions.InitAsync();
    if (!responses.error) {
      let checkList = responses[0].data;
      let employeeData = responses[1].data;

      setRecords(checkList);
      setEmployee(employeeData);

      //Load Finished
      let result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    } else {
      //Load with Error
      let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }

  const saveData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let headers = { [enumRequestHeader.Header_Employee]: EmployeeID };
      let response = await new EmployeeCheckListActions().SaveAsync(
        Records,
        headers
      );

      if (!response.error) {
        await GetInfoAsync();

        //Load Finished
        result = msgHelper.setPhase(enumPage.SaveSuccess);
        setMessageData(result);
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Ako ima Validation Messages
      }
    }

    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */

  const handleChangeCheck = (e) => {
    const { name, value } = e.target; //vo name-ot go imame proektot
    console.error(name + ":" + value);
    //setRecord((prevState) => ({ ...prevState, [name]: value }));

    let LookupDataID = name.replace("IsChecked_", ""); //za da moze da menuvam
    let propertyName = "IsChecked";

    //najdi go od nizata samiot record i updatetiraj go
    let newRecords = [...Records]; //Records.map( x => ({...x}) )
    let currentCheck = newRecords.find(
      (a) => a.ldLookupDataID.toString() === LookupDataID.toString()
    );

    console.error("BEFORE CHANGES");
    console.error(newRecords);
    currentCheck[propertyName] = value;

    if (!value) {
      currentCheck["CreatedBy"] = "N/A";
      currentCheck["CreatedDate"] = new Date(1900, 1, 1);
    }

    //za vo koj red od nizata da go setira so findInex
    let propertyIndex = newRecords.findIndex(
      (x) => x.ldLookupDataID.toString() === LookupDataID.toString()
    );
    newRecords[propertyIndex] = currentCheck;
    //Records[0] = {ldLookupDataID: 99, ldValue: "ZOKI", IsChecked: false};

    //let newRecords = Records.map( x => ({...x}) )
    setRecords(newRecords);
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ActionButtons = () => {
    return (
      <Grid container align="right" padding={1}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            name="btnSave"
            color="success"
            size="small"
            disabled={MessageData.Loading}
            onClick={() => saveData()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3" display="block">
            {Employee.eyFullName}
          </Typography>
        </Grid>
      </Grid>

      <EmployeeBreadcrumbs
        page={enumBreadcrumbPage.CheckList}
        Id={Record.ctEmployeeID}
        disabled={MessageData.Loading}
      />
      <MyAlert {...MessageData} />

      <EmployeeHeaderButtons />
      <ActionButtons />

      <Grid container direction="column" alignItems="flex-start" align="left">
        <Grid container align="left">
          <Grid item xs={12} md={4}>
            <Typography variant="h4">Procedure</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant="h4">Created By</Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography variant="h4">Created Date</Typography>
          </Grid>
        </Grid>

        {Records.sort((a, b) => a.ldValue.localeCompare(b.ldValue)).map(
          (row, index) => (
            <Grid container>
              <Grid item xs={12} md={4} key={index}>
                <MyCheckboxTable
                  key={index}
                  Id={"IsChecked_" + row.ldLookupDataID}
                  Record={row}
                  PlaceHolder={row.ldValue}
                  HandleChange={handleChangeCheck}
                  Required={true}
                  Disabled={false}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2">
                  {row.CreatedBy.replace("N/A", "")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={1}>
                <Typography variant="subtitle2">
                  {moment(row.CreatedDate).format("YYYY") === "1900"
                    ? ""
                    : moment(row.CreatedDate).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
            </Grid>
          )
        )}
      </Grid>
    </Typography>
  );
}
