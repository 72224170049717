import { ValidatorHelper } from "../helpers/ValidatorHelper";
import { MyFinTimeService } from "../services/MyFinTimeService";

export default class MyFinTimeActions {


  async EditAsync(Record: any) {
    let response = await new MyFinTimeService().Edit(Record);
    
    let responses = [response];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }



  async SearchAsync(Record: any) {
    //napravi validacija
    let response = await new MyFinTimeService().Search(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async SaveAsync(Record: any) {
    //napravi validacija
    
    let response = await new MyFinTimeService().Save(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

}
